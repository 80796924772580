<template>
  <component
    :is="name"
    v-if="doesIconExist"
    class="obj_web_icon"
    :class="{
      'obj_web_icon--big-on-mobile': isBigOnMobile,
      'obj_web_icon--2xs': size === '2xs',
      'obj_web_icon--xs': size === 'xs',
      'obj_web_icon--sm': size === 'sm',
      'obj_web_icon--md': size === 'md',
      'obj_web_icon--ml': size === 'ml',
      'obj_web_icon--lg': size === 'lg',
      'obj_web_icon--xl': size === 'xl',
      'obj_web_icon--2xl': size === '2xl',
      'obj_web_icon--3xl': size === '3xl',
      'obj_web_icon--4xl': size === '4xl',
    }"
  />
</template>

<script>
import activity from '@/assets/icons/pf-icon-activity.svg?component'
import adopt from '@/assets/icons/pf-icon-adopt.svg?component'
import approval from '@/assets/icons/pf-icon-approval.svg?component'
import arrowDown from '@/assets/icons/pf-icon-arrow-down.svg?component'
import arrowLeft from '@/assets/icons/pf-icon-arrow-left.svg?component'
import arrowLongLeft from '@/assets/icons/pf-icon-arrow-long-left.svg?component'
import arrowLongRight from '@/assets/icons/pf-icon-arrow-long-right.svg?component'
import arrowRight from '@/assets/icons/pf-icon-arrow-right.svg?component'
import arrowUp from '@/assets/icons/pf-icon-arrow-up.svg?component'
import attachment from '@/assets/icons/pf-icon-attachment.svg?component'
import attention from '@/assets/icons/pf-icon-attention.svg?component'
import bug from '@/assets/icons/pf-icon-bug.svg?component'
import camera from '@/assets/icons/pf-icon-camera.svg?component'
import category from '@/assets/icons/pf-icon-category.svg?component'
import checkmarkCircle from '@/assets/icons/pf-icon-checkmark-circle.svg?component'
import checkmark from '@/assets/icons/pf-icon-checkmark.svg?component'
import chevronDown from '@/assets/icons/pf-icon-chevron-down.svg?component'
import chevronLeft from '@/assets/icons/pf-icon-chevron-left.svg?component'
import chevronRight from '@/assets/icons/pf-icon-chevron-right.svg?component'
import chevronUp from '@/assets/icons/pf-icon-chevron-up.svg?component'
import circleIcon from '@/assets/icons/pf-icon-circle.svg?component'
import clipboard from '@/assets/icons/pf-icon-clipboard.svg?component'
import closeCircle from '@/assets/icons/pf-icon-close-circle.svg?component'
import comment from '@/assets/icons/pf-icon-comment.svg?component'
import copy from '@/assets/icons/pf-icon-copy.svg?component'
import crossmark from '@/assets/icons/pf-icon-crossmark.svg?component'
import deleteIcon from '@/assets/icons/pf-icon-delete.svg?component'
import download from '@/assets/icons/pf-icon-download.svg?component'
import draftCircle from '@/assets/icons/pf-icon-draft-circle.svg?component'
import drag from '@/assets/icons/pf-icon-drag.svg?component'
import duedate from '@/assets/icons/pf-icon-duedate.svg?component'
import edit from '@/assets/icons/pf-icon-edit.svg?component'
import ellipsisHorizontalCircle from '@/assets/icons/pf-icon-ellipsis-horizontal-circle.svg?component'
import ellipsisHorizontal from '@/assets/icons/pf-icon-ellipsis-horizontal.svg?component'
import emailPlus from '@/assets/icons/pf-icon-email-plus.svg?component'
import email from '@/assets/icons/pf-icon-email.svg?component'
import exclamationMark from '@/assets/icons/pf-icon-exclamation-mark.svg?component'
import expand from '@/assets/icons/pf-icon-expand.svg?component'
import exportIcon from '@/assets/icons/pf-icon-export.svg?component'
import faq from '@/assets/icons/pf-icon-faq.svg?component'
import file from '@/assets/icons/pf-icon-file.svg?component'
import filterIcon from '@/assets/icons/pf-icon-filter.svg?component'
import folderClosed from '@/assets/icons/pf-icon-folder-closed.svg?component'
import folderOpen from '@/assets/icons/pf-icon-folder-open.svg?component'
import fullscreen from '@/assets/icons/pf-icon-fullscreen.svg?component'
import hamburger from '@/assets/icons/pf-icon-hamburger.svg?component'
import help from '@/assets/icons/pf-icon-help.svg?component'
import importIcon from '@/assets/icons/pf-icon-import.svg?component'
import inProcessCircle from '@/assets/icons/pf-icon-in-process-circle.svg?component'
import infoCircle from '@/assets/icons/pf-icon-info-circle.svg?component'
import invisible from '@/assets/icons/pf-icon-invisible.svg?component'
import linkIcon from '@/assets/icons/pf-icon-link.svg?component'
import listView from '@/assets/icons/pf-icon-list-view.svg?component'
import location from '@/assets/icons/pf-icon-location.svg?component'
import locked from '@/assets/icons/pf-icon-locked.svg?component'
import newFeature from '@/assets/icons/pf-icon-new-feature.svg?component'
import nextCircle from '@/assets/icons/pf-icon-next-circle.svg?component'
import notification from '@/assets/icons/pf-icon-notification.svg?component'
import owner from '@/assets/icons/pf-icon-owner.svg?component'
import personalSupport from '@/assets/icons/pf-icon-personal-support.svg?component'
import phone from '@/assets/icons/pf-icon-phone.svg?component'
import plan from '@/assets/icons/pf-icon-plan.svg?component'
import plus from '@/assets/icons/pf-icon-plus.svg?component'
import prevCircle from '@/assets/icons/pf-icon-prev-circle.svg?component'
import print from '@/assets/icons/pf-icon-print.svg?component'
import projectLang from '@/assets/icons/pf-icon-project-lang.svg?component'
import reorder from '@/assets/icons/pf-icon-reorder.svg?component'
import reset from '@/assets/icons/pf-icon-reset.svg?component'
import restore from '@/assets/icons/pf-icon-restore.svg?component'
import role from '@/assets/icons/pf-icon-role.svg?component'
import screenSupport from '@/assets/icons/pf-icon-screen-support.svg?component'
import search from '@/assets/icons/pf-icon-search.svg?component'
import settings from '@/assets/icons/pf-icon-settings.svg?component'
import share from '@/assets/icons/pf-icon-share.svg?component'
import shrink from '@/assets/icons/pf-icon-shrink.svg?component'
import sortDown from '@/assets/icons/pf-icon-sort-down.svg?component'
import sortHorizontal from '@/assets/icons/pf-icon-sort-horizontal.svg?component'
import sortUp from '@/assets/icons/pf-icon-sort-up.svg?component'
import sort from '@/assets/icons/pf-icon-sort.svg?component'
import star from '@/assets/icons/pf-icon-star.svg?component'
import sync from '@/assets/icons/pf-icon-sync.svg?component'
import tag from '@/assets/icons/pf-icon-tag.svg?component'
import tasks from '@/assets/icons/pf-icon-tasks.svg?component'
import templateIcon from '@/assets/icons/pf-icon-template.svg?component'
import textblock from '@/assets/icons/pf-icon-textblock.svg?component'
import tips from '@/assets/icons/pf-icon-tips.svg?component'
import topCategory from '@/assets/icons/pf-icon-top-category.svg?component'
import trash from '@/assets/icons/pf-icon-trash.svg?component'
import unlocked from '@/assets/icons/pf-icon-unlocked.svg?component'
import update from '@/assets/icons/pf-icon-update.svg?component'
import upload from '@/assets/icons/pf-icon-upload.svg?component'
import userAccount from '@/assets/icons/pf-icon-user-account.svg?component'
import userAdmin from '@/assets/icons/pf-icon-user-admin.svg?component'
import userParticipant from '@/assets/icons/pf-icon-user-participant.svg?component'
import userReadOnly from '@/assets/icons/pf-icon-user-read-only.svg?component'
import userSilentParticipant from '@/assets/icons/pf-icon-user-silent-participant.svg?component'
import user from '@/assets/icons/pf-icon-user.svg?component'
import visible from '@/assets/icons/pf-icon-visible.svg?component'

export default {
  name: 'PfIcon',

  components: {
    activity,
    adopt,
    approval,
    attachment,
    attention,
    arrowDown,
    arrowLeft,
    arrowRight,
    arrowUp,
    arrowLongLeft,
    arrowLongRight,
    bug,
    camera,
    category,
    checkmark,
    checkmarkCircle,
    chevronDown,
    chevronLeft,
    chevronRight,
    chevronUp,
    circleIcon,
    clipboard,
    closeCircle,
    comment,
    copy,
    crossmark,
    deleteIcon,
    download,
    draftCircle,
    drag,
    duedate,
    edit,
    ellipsisHorizontal,
    ellipsisHorizontalCircle,
    email,
    emailPlus,
    exclamationMark,
    expand,
    exportIcon,
    faq,
    file,
    filterIcon,
    folderClosed,
    folderOpen,
    fullscreen,
    hamburger,
    help,
    importIcon,
    inProcessCircle,
    infoCircle,
    invisible,
    linkIcon,
    listView,
    location,
    locked,
    newFeature,
    nextCircle,
    notification,
    owner,
    personalSupport,
    phone,
    plan,
    plus,
    prevCircle,
    print,
    projectLang,
    reorder,
    restore,
    reset,
    role,
    screenSupport,
    search,
    settings,
    share,
    shrink,
    sort,
    sortDown,
    sortHorizontal,
    sortUp,
    star,
    sync,
    tag,
    tasks,
    templateIcon,
    textblock,
    tips,
    topCategory,
    trash,
    unlocked,
    update,
    upload,
    user,
    userAccount,
    userAdmin,
    userParticipant,
    userSilentParticipant,
    userReadOnly,
    visible,
  },

  props: {
    name: {
      type: String,
      required: true,
    },

    isBigOnMobile: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      default: '',
    },
  },

  computed: {
    doesIconExist () {
      return this.$options.components[this.name]
    },
  },

  created () {
    if (!this.doesIconExist) {
      throw new Error(`Icon "${this.name}" does not exist (or not defined in the PfIcon component)`)
    }
  },
}
</script>
