/**
 * 31.8.2020
 * Affects plans and documents store:
 * subProperty printSettings.groupByPhase gets replaced by printSettings.groupBy, to be able to group by different types
 * ATTENTION: The groupByPhase version was only deployed on the APG server
 */
const migrateGroupByPhaseToGroupBy = ({ $config, lsContent }) => {
  // Migrate plans store
  if (lsContent.plans && lsContent.plans.printSettings && 'groupByPhase' in lsContent.plans.printSettings) { // We use the "in" check here, because we also want to get into the "if", if groupByPhase is false
    const groupByPhaseValue = lsContent.plans.printSettings.groupByPhase

    if (groupByPhaseValue) {
      lsContent.plans.printSettings.groupBy = $config.constants.ITEM_PRINT_SETTINGS.GROUP_BY_PHASETAGS
    }

    delete lsContent.plans.printSettings.groupByPhase
  }

  // Migrate documents store (same as above, but for documents)
  if (lsContent.documents && lsContent.documents.printSettings && 'groupByPhase' in lsContent.documents.printSettings) {
    const groupByPhaseValue = lsContent.documents.printSettings.groupByPhase

    if (groupByPhaseValue) {
      lsContent.documents.printSettings.groupBy = $config.constants.ITEM_PRINT_SETTINGS.GROUP_BY_PHASETAGS
    }

    delete lsContent.documents.printSettings.groupByPhase
  }

  return lsContent
}

/**
 * 18.11.2020
 * Affects plansNewPrefill and documentsNewPrefill store:
 * The plan/document.createdby property got renamed to "author". We have to reflect this change in the prefill options
 */
const migrateCreatedByToAuthor = ({ $config, lsContent }) => {
  // Migrate plansNewPrefill store
  if (lsContent.plansNewPrefill && lsContent.plansNewPrefill.features && lsContent.plansNewPrefill.features.useCreatedBy !== undefined) {
    lsContent.plansNewPrefill.features.useAuthor = lsContent.plansNewPrefill.features.useCreatedBy

    delete lsContent.plansNewPrefill.features.useCreatedBy
  }

  // Migrate documentsNewPrefill store
  if (lsContent.documentsNewPrefill && lsContent.documentsNewPrefill.features && lsContent.documentsNewPrefill.features.useCreatedBy !== undefined) {
    lsContent.documentsNewPrefill.features.useAuthor = lsContent.documentsNewPrefill.features.useCreatedBy

    delete lsContent.documentsNewPrefill.features.useCreatedBy
  }

  return lsContent
}

/**
 * 20.09.2022
 * Affects tasksListModus store:
 * The filterStoreName values got refactored AND the value got moved to a getter. So we just remove the filterStoreName value from the localStorage
 */
const migrateTasksListModus = ({ $config, lsContent }) => {
  // Migrate plansNewPrefill store
  if (lsContent.tasksListModus && lsContent.tasksListModus.filterStoreName) {
    delete lsContent.tasksListModus.filterStoreName
  }

  return lsContent
}

/**
 * 14.02.2023
 * Affects plansFilter, plansFilterModusApproval, plansFilterModusTrash, documentsFilter, documentsFilterModusApproval, documentsFilterModusTrash store:
 * The "role" property got renamed to "authorRoles"
 */
const migrateItemRolesFilter = ({ $config, lsContent }) => {
  const migrateFilterStore = filterName => {
    const roles = lsContent[filterName] && lsContent[filterName].roles

    if (roles && roles.length > 0) {
      lsContent[filterName].authorRoles = [...roles]
      delete lsContent[filterName].roles
    }
  }

  migrateFilterStore('plansFilter')
  migrateFilterStore('plansFilterModusApproval')
  migrateFilterStore('plansFilterModusTrash')
  migrateFilterStore('documentsFilter')
  migrateFilterStore('documentsFilterModusApproval')
  migrateFilterStore('documentsFilterModusTrash')

  return lsContent
}

/**
 * 21.01.2025
 * delete isOpen flag from tasksNewPrefill, documentsNewPrefill and plansNewPrefill stores
 */
const migratePrefillStores = ({
  $config,
  lsContent,
}) => {
  if (lsContent.plansNewPrefill && lsContent.plansNewPrefill.isOpen !== undefined) {
    delete lsContent.plansNewPrefill.isOpen
  }

  if (lsContent.documentsNewPrefill && lsContent.documentsNewPrefill.isOpen !== undefined) {
    delete lsContent.documentsNewPrefill.isOpen
  }

  if (lsContent.tasksNewPrefill && lsContent.tasksNewPrefill.isOpen !== undefined) {
    delete lsContent.tasksNewPrefill.isOpen
  }

  return lsContent
}

/**
 * 08.05.2023
 * Affects auth._token.local localStorage:
 * Since the nuxt2 => vue3 upgrade, we don't save the string "Bearer" in the auth._token.local localStorage anymore.
 * Before migration (nuxt2): "Bearer abc"
 * After migration (vue3): "abc"
 * To prevent logouts after the nuxt2 => vue3 migration release, we just migrate the localStorage
 */
const migrateAuthTokenBearer = () => {
  const lsKey = 'auth._token.local'
  const lsContent = localStorage.getItem(lsKey)

  if (lsContent && lsContent.includes('Bearer ')) {
    localStorage.setItem(lsKey, lsContent.replace('Bearer ', ''))
  }
}

export default {
  install: (app) => {
    const $config = app.config.globalProperties.$config
    const lsKeys = Object.keys(localStorage)
    const lsKeysFiltered = lsKeys.filter(key => key.startsWith($config.constants.LOCAL_STORAGE_PREFIX))

    migrateAuthTokenBearer()

    lsKeysFiltered.forEach(lsKey => {
      try {
        let lsContent = JSON.parse(localStorage.getItem(lsKey))

        if (lsContent) {
        // PUT FUTURE MIGRATIONS HERE
          lsContent = migrateGroupByPhaseToGroupBy({ $config, lsContent })
          lsContent = migrateCreatedByToAuthor({ $config, lsContent })
          lsContent = migrateTasksListModus({ $config, lsContent })
          lsContent = migrateItemRolesFilter({ $config, lsContent })
          lsContent = migratePrefillStores({ $config, lsContent })

          localStorage.setItem(lsKey, JSON.stringify(lsContent))
        }
      } catch (err) {
        console.error(err)
      }
    })
  },
}
