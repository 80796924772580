<template>
  <PfModal
    :show-close="false"
    data-testid="agb-dsgvo-modal"
    :locked-ids="[]"
    class="com_modal--no-header com_modal--v-align-top"
  >
    <div
      class="badge-dsgvo"
    >
      <img
        :src="$t('feature.agb.modal.dsgvo_image_path')"
        :alt="$t('feature.agb.modal.dsgvo_image_alt')"
      >
    </div>

    <h2
      class="uti_margin-top-0"
    >
      {{ $t('feature.agb.modal.dear_user') }}
    </h2>

    <h6>
      {{ $t('feature.agb.modal.may_we_have_your_attention') }}
    </h6>

    <p>
      <span>
        {{ $t('feature.agb.modal.new_dsgvo_since_2018') }}
      </span>

      {{ $t('feature.agb.modal.planfred_supports_dsgvo_since_ever') }}
    </p>

    <h6>
      {{ $t('feature.agb.modal.please_confirm') }}
    </h6>

    <div>
      <AgbDsgvoSubForm
        :form="form"
        :show-validation-error="state === validStates.SUBMIT_CLICKED"
        @on-change-confirmed-agb="onChangeConfirmedAgb"
        @on-change-confirmed-dsgvo="onChangeConfirmedDsgvo"
      />

      <PfButtonBar
        class="uti_margin-top-lg"
      >
        <PfButtonBarButton
          data-testid="agb-dsgvo-modal-submit"
          :is-enabled="form.confirmedAgb && form.confirmedDsgvo"
          type="submit"
          @click="onClickSave"
        >
          {{ $t('feature.agb.modal.submit') }}
        </PfButtonBarButton>

        <PfButtonBarLink
          data-testid="cancel-agbprivacy-modal"
          @click="onClickCancel"
        >
          {{ $t('feature.agb.modal.cancel') }}
        </PfButtonBarLink>

        <template #error>
          <MessageBoxError
            v-if="state === validStates.SUBMIT_ERROR"
            data-testid="error-agb-dsgvo-modal"
            :message="$t('feature.agb.modal.submit_server_error')"
          />
        </template>
      </PfButtonBar>
    </div>

    <SupportInfo
      class="public-supportbar--modal-agb-dsgvo"
    />
  </PfModal>
</template>

<script>
import { confirmAgbDsgvo } from '@client-shared/api/users.api.js'

import AgbDsgvoSubForm from '@/components/AgbDsgvoSubForm.vue'
import MessageBoxError from '@/components/MessageBoxError.vue'
import PfButtonBar from '@/components/PfButtonBar.vue'
import PfButtonBarButton from '@/components/PfButtonBarButton.vue'
import PfButtonBarLink from '@/components/PfButtonBarLink.vue'
import PfModal from '@/components/PfModal.vue'
import SupportInfo from '@/components/SupportInfo.vue'

export default {
  name: 'ModalAgbDsgvo',

  components: {
    PfButtonBarButton,
    PfButtonBarLink,
    PfButtonBar,
    AgbDsgvoSubForm,
    MessageBoxError,
    PfModal,
    SupportInfo,
  },

  data () {
    const validStates = Object.freeze(
      {
        INITIAL: 1,
        SUBMIT_CLICKED: 2,
        SUBMIT_PENDING: 3,
        SUBMIT_ERROR: 4,
      },
    )

    return {
      validStates,
      state: validStates.INITIAL,
      form: {
        confirmedAgb: !!this.$auth.user.crm.confirmedAGB,
        confirmedDsgvo: !!this.$auth.user.crm.confirmedDSGVO,
      },
    }
  },

  methods: {
    onChangeConfirmedAgb (confirmedAgb) {
      this.form.confirmedAgb = confirmedAgb
    },

    onChangeConfirmedDsgvo (confirmedDsgvo) {
      this.form.confirmedDsgvo = confirmedDsgvo
    },

    async onClickCancel () {
      await this.$auth.logout()
    },

    async onClickSave () {
      if (this.state === this.validStates.SUBMIT_PENDING) {
        return
      }

      this.state = this.validStates.SUBMIT_CLICKED

      if (!this.form.confirmedAgb || !this.form.confirmedDsgvo) {
        return
      }

      this.state = this.validStates.SUBMIT_PENDING

      try {
        await confirmAgbDsgvo({
          axios: this.$axios,
          userId: this.$auth.user._id,
        })
        await this.$auth.fetchUser()

        this.state = this.validStates.INITIAL
      } catch (err) {
        this.state = this.validStates.SUBMIT_ERROR
      }
    },
  },
}
</script>
