import { createStore, createLogger } from 'vuex'

// import { createLogger } from 'vuex'
import acl from '@/store/acl'
import activities from '@/store/activities'
import activitiesFilter from '@/store/activitiesFilter'
import aggregators from '@/store/aggregators'
import clipboard from '@/store/clipboard'
import clipboardFileAssignment from '@/store/clipboardFileAssignment'
import currentUser from '@/store/currentUser'
import documents from '@/store/documents'
import documentsFilter from '@/store/documentsFilter'
import documentsFilterModusApproval from '@/store/documentsFilterModusApproval'
import documentsFilterModusTrash from '@/store/documentsFilterModusTrash'
import documentsImport from '@/store/documentsImport'
import documentsImportFilter from '@/store/documentsImportFilter'
import documentsListModus from '@/store/documentsListModus'
import documentsNewPrefill from '@/store/documentsNewPrefill'
import download from '@/store/download'
import help from '@/store/help'
import layout from '@/store/layout'
import locationLayers from '@/store/locationLayers'
import participants from '@/store/participants'
import participantsCreator from '@/store/participantsCreator'
import participantsFilter from '@/store/participantsFilter'
import participantsFilterModusTrash from '@/store/participantsFilterModusTrash'
import participantsImport from '@/store/participantsImport'
import participantsListModus from '@/store/participantsListModus'
import plans from '@/store/plans'
import plansFilter from '@/store/plansFilter'
import plansFilterModusApproval from '@/store/plansFilterModusApproval'
import plansFilterModusTrash from '@/store/plansFilterModusTrash'
import plansImport from '@/store/plansImport'
import plansImportFilter from '@/store/plansImportFilter'
import plansListModus from '@/store/plansListModus'
import plansNewPrefill from '@/store/plansNewPrefill'
import filterToServerSync from '@/store/plugins/filter-to-server-sync'
import localStorageSync from '@/store/plugins/local-storage-sync'
import project from '@/store/project'
import projects from '@/store/projects'
import projectsFilter from '@/store/projectsFilter'
import recipientGroups from '@/store/recipientGroups'
import tasks from '@/store/tasks'
import tasksFilterDone from '@/store/tasksFilterDone'
import tasksFilterDoneMapView from '@/store/tasksFilterDoneMapView'
import tasksFilterDrafts from '@/store/tasksFilterDrafts'
import tasksFilterDraftsMapView from '@/store/tasksFilterDraftsMapView'
import tasksFilterMain from '@/store/tasksFilterMain'
import tasksFilterMainMapView from '@/store/tasksFilterMainMapView'
import tasksFilterTrash from '@/store/tasksFilterTrash'
import tasksListModus from '@/store/tasksListModus'
import tasksNewPrefill from '@/store/tasksNewPrefill'
import tasksSortingModalAppendPinToTask from '@/store/tasksSortingModalAppendPinToTask'
import textblocks from '@/store/textblocks'
import unsavedChanges from '@/store/unsavedChanges'

const isLoggingEnabled = process.env.NODE_ENV === 'production' && false

export default createStore({
  strict: process.env.NODE_ENV !== 'production',

  modules: {
    acl,
    activities,
    activitiesFilter,
    aggregators,
    clipboard,
    clipboardFileAssignment,
    currentUser,
    documents,
    documentsFilter,
    documentsFilterModusApproval,
    documentsFilterModusTrash,
    documentsImport,
    documentsImportFilter,
    documentsListModus,
    documentsNewPrefill,
    download,
    layout,
    locationLayers,
    participants,
    participantsCreator,
    participantsFilter,
    participantsFilterModusTrash,
    participantsImport,
    participantsListModus,
    plans,
    plansFilter,
    plansFilterModusApproval,
    plansFilterModusTrash,
    plansImport,
    plansImportFilter,
    plansListModus,
    plansNewPrefill,
    project,
    projects,
    projectsFilter,
    recipientGroups,
    tasks,
    tasksNewPrefill,
    tasksFilterDone,
    tasksFilterDrafts,
    tasksFilterMain,
    tasksFilterDoneMapView,
    tasksFilterDraftsMapView,
    tasksFilterMainMapView,
    tasksFilterTrash,
    tasksListModus,
    textblocks,
    unsavedChanges,
    tasksSortingModalAppendPinToTask,
    help,
  },

  // ATTENTION: createLogger() is responsible for a HUGE performance impact if the task list grows. So make sure to remove it, when working with big lists (or on production)
  plugins: isLoggingEnabled
    ? [localStorageSync, filterToServerSync, createLogger()]
    : [localStorageSync, filterToServerSync],
})
