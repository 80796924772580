import Document from '@client-shared/models/document.model'
import LocationLayer from '@client-shared/models/location-layer.model'
import Participant from '@client-shared/models/participant.model'
import Plan from '@client-shared/models/plan.model'
import Project from '@client-shared/models/project.model'
import Task from '@client-shared/models/task.model'
import generateRandomId from '@client-shared/utils/crypto-random-uuid.js'
import { io } from 'socket.io-client'

import i18nInstance from '@/plugins/i18n'

const $t = i18nInstance.global.t

let queuedEvents = []
const socketHandlers = {
  PARTICIPANT_NEW: {
    getResourceId: ({ jsonPayload }) => {
      const { participant } = jsonPayload

      return participant._id
    },
    handler: ({ app, jsonPayload }) => {
      const { projectId, participant } = jsonPayload
      const store = app.config.globalProperties.$store

      if (projectId !== store.state.project.project._id) {
        throw new Error('ProjectId of PARTICIPANT_NEW event does not match with currently loaded project')
      }

      store.commit('project/ADD_PARTICIPANT', new Participant(participant))
    },
  },

  PARTICIPANT_UPDATE: {
    getResourceId: ({ jsonPayload }) => {
      const { participant } = jsonPayload

      return participant._id
    },
    handler: ({ app, jsonPayload }) => {
      const { projectId, participant } = jsonPayload
      const store = app.config.globalProperties.$store

      if (projectId !== store.state.project.project._id) {
        throw new Error('ProjectId of PARTICIPANT_UPDATE event does not match with currently loaded project')
      }

      store.commit('project/UPDATE_PARTICIPANT', new Participant(participant))
    },
  },

  PROJECT_UPDATE: {
    getResourceId: ({ jsonPayload }) => {
      const { project } = jsonPayload

      return project._id
    },
    handler: ({ app, jsonPayload }) => {
      const { project } = jsonPayload
      const store = app.config.globalProperties.$store

      if (project._id !== store.state.project.project._id) {
        throw new Error('ProjectId of PROJECT_UPDATE event does not match with currently loaded project')
      }

      store.commit('project/SET_PROJECT', new Project(project))
    },
  },

  TASK_NEW: {
    getResourceId: ({ jsonPayload }) => {
      const { task } = jsonPayload

      return task._id
    },
    handler: ({ app, jsonPayload }) => {
      const { projectId, task } = jsonPayload
      const store = app.config.globalProperties.$store

      if (projectId !== store.state.project.project._id) {
        throw new Error('ProjectId of TASK_NEW event does not match with currently loaded project')
      }

      store.commit('tasks/ADD_TASK', new Task(task))
    },
  },

  TASK_UPDATE: {
    getResourceId: ({ jsonPayload }) => {
      const { task } = jsonPayload

      return task._id
    },
    handler: ({ app, jsonPayload }) => {
      const { projectId, task } = jsonPayload
      const store = app.config.globalProperties.$store

      if (projectId !== store.state.project.project._id) {
        throw new Error('ProjectId of TASK_UPDATE event does not match with currently loaded project')
      }

      store.commit('tasks/ADD_TASK', new Task(task))
    },
  },

  TASK_FILE_THUMBNAIL_UPDATE: {
    getResourceId: ({ jsonPayload }) => {
      const { task } = jsonPayload

      return task._id
    },
    handler: ({ app, jsonPayload }) => {
      const { projectId, taskId, task } = jsonPayload
      const store = app.config.globalProperties.$store

      if (projectId !== store.state.project.project._id) {
        throw new Error('ProjectId of TASK_FILE_THUMBNAIL_UPDATE event does not match with currently loaded project')
      }

      const originalTask = store.getters['tasks/getById'](taskId)

      if (!originalTask) {
        console.error(`Original task with _id "${taskId}" couldn't be found`)
        return
      }

      store.commit('tasks/REPLACE_TASK', new Task(task))
    },
  },
  TASK_COMMENT_FILE_THUMBNAIL_UPDATE: {
    getResourceId: ({ jsonPayload }) => {
      const { task } = jsonPayload

      return task._id
    },
    handler: ({ app, jsonPayload }) => {
      const { projectId, taskId, task } = jsonPayload
      const store = app.config.globalProperties.$store

      if (projectId !== store.state.project.project._id) {
        throw new Error('ProjectId of TASK_COMMENT_FILE_THUMBNAIL_UPDATE event does not match with currently loaded project')
      }

      const originalTask = store.getters['tasks/getById'](taskId)

      if (!originalTask) {
        console.error(`Original task with _id "${taskId}" couldn't be found`)
        return
      }

      store.commit('tasks/REPLACE_TASK', new Task(task))
    },
  },

  TASK_COMMENT_NEW: {
    getResourceId: ({ jsonPayload }) => {
      const { task } = jsonPayload

      return task._id
    },
    handler: ({ app, jsonPayload }) => {
      const { projectId, task } = jsonPayload
      const store = app.config.globalProperties.$store

      if (projectId !== store.state.project.project._id) {
        throw new Error('ProjectId of TASK_COMMENT_NEW event does not match with currently loaded project')
      }
      store.commit('tasks/REPLACE_TASK', new Task(task))
    },
  },

  TASK_COMMENT_UPDATE: {
    getResourceId: ({ jsonPayload }) => {
      const { task } = jsonPayload

      return task._id
    },
    handler: ({ app, jsonPayload }) => {
      const { projectId, task } = jsonPayload
      const store = app.config.globalProperties.$store

      if (projectId !== store.state.project.project._id) {
        throw new Error('ProjectId of TASK_COMMENT_UPDATE event does not match with currently loaded project')
      }

      store.commit('tasks/REPLACE_TASK', new Task(task))
    },
  },

  TASK_REMOVE_VIEW_PERMISSION: {
    getResourceId: ({ jsonPayload }) => {
      const { taskId } = jsonPayload

      return taskId
    },
    handler: ({ app, jsonPayload }) => {
      const { projectId, taskId } = jsonPayload
      const store = app.config.globalProperties.$store

      if (projectId !== store.state.project.project._id) {
        throw new Error('ProjectId of TASK_REMOVE_VIEW_PERMISSION event does not match with currently loaded project')
      }

      store.commit('tasks/REMOVE_TASK', taskId)

      if (app.config.globalProperties.$route.name === 'projects-projectId-tasks-taskId' && app.config.globalProperties.$route.params.taskId === taskId) {
        alert(`${$t('feature.task.detail.task_not_available')} - ${$t('feature.task.detail.maybe_no_permission_to_view_task')}`)

        app.config.globalProperties.$router.push({
          name: 'projects-projectId-tasks',
          params: {
            projectId: store.state.project.project._id,
          },
        })
      }
    },
  },

  PLAN_NEW: {
    getResourceId: ({ jsonPayload }) => {
      const { plan } = jsonPayload

      return plan._id
    },
    handler: ({ app, jsonPayload }) => {
      const { projectId, plan } = jsonPayload
      const store = app.config.globalProperties.$store

      if (projectId !== store.state.project.project._id) {
        throw new Error('ProjectId of PLAN_NEW event does not match with currently loaded project')
      }

      store.commit('plans/ADD_PLAN', new Plan(plan))
    },
  },

  PLAN_UPDATE: {
    getResourceId: ({ jsonPayload }) => {
      const { plan } = jsonPayload

      return plan._id
    },
    handler: ({ app, jsonPayload }) => {
      const { projectId, plan } = jsonPayload
      const store = app.config.globalProperties.$store

      if (projectId !== store.state.project.project._id) {
        throw new Error('ProjectId of PLAN_UPDATE event does not match with currently loaded project')
      }

      store.commit('plans/ADD_PLAN', new Plan(plan))
    },
  },

  PLAN_REVISION_NEW: {
    getResourceId: ({ jsonPayload }) => {
      const { plan } = jsonPayload

      return plan._id
    },
    handler: ({ app, jsonPayload }) => {
      const { projectId, plan } = jsonPayload
      const store = app.config.globalProperties.$store

      if (projectId !== store.state.project.project._id) {
        throw new Error('ProjectId of PLAN_REVISION_NEW event does not match with currently loaded project')
      }

      store.commit('plans/REPLACE_PLAN', new Plan(plan))
    },
  },

  DOCUMENT_NEW: {
    getResourceId: ({ jsonPayload }) => {
      const { document } = jsonPayload

      return document._id
    },
    handler: ({ app, jsonPayload }) => {
      const { projectId, document } = jsonPayload
      const store = app.config.globalProperties.$store

      if (projectId !== store.state.project.project._id) {
        throw new Error('ProjectId of DOCUMENT_NEW event does not match with currently loaded project')
      }

      store.commit('documents/ADD_DOCUMENT', new Document(document))
    },
  },

  DOCUMENT_UPDATE: {
    getResourceId: ({ jsonPayload }) => {
      const { document } = jsonPayload

      return document._id
    },
    handler: ({ app, jsonPayload }) => {
      const { projectId, document } = jsonPayload
      const store = app.config.globalProperties.$store

      if (projectId !== store.state.project.project._id) {
        throw new Error('ProjectId of DOCUMENT_UPDATE event does not match with currently loaded project')
      }

      store.commit('documents/ADD_DOCUMENT', new Document(document))
    },
  },

  DOCUMENT_REVISION_NEW: {
    getResourceId: ({ jsonPayload }) => {
      const { document } = jsonPayload

      return document._id
    },
    handler: ({ app, jsonPayload }) => {
      const { projectId, document } = jsonPayload
      const store = app.config.globalProperties.$store

      if (projectId !== store.state.project.project._id) {
        throw new Error('ProjectId of DOCUMENT_REVISION_NEW event does not match with currently loaded project')
      }

      store.commit('documents/REPLACE_DOCUMENT', new Document(document))
    },
  },

  DOCUMENT_REMOVE_VIEW_PERMISSION: {
    getResourceId: ({ jsonPayload }) => {
      const { documentId } = jsonPayload

      return documentId
    },
    handler: ({ app, jsonPayload }) => {
      const { projectId, documentId } = jsonPayload
      const store = app.config.globalProperties.$store

      if (projectId !== store.state.project.project._id) {
        throw new Error('ProjectId of DOCUMENT_REMOVE_VIEW_PERMISSION event does not match with currently loaded project')
      }

      store.commit('documents/REMOVE_DOCUMENT', documentId)

      if (app.config.globalProperties.$route.name === 'projects-projectId-documents-documentId' && app.config.globalProperties.$route.params.documentId === documentId) {
        alert(`${$t('feature.item.document_not_available')} - ${$t('feature.item.maybe_no_permission_to_view_document')}`)

        app.config.globalProperties.$router.push({
          name: 'projects-projectId-documents',
          params: {
            projectId: store.state.project.project._id,
          },
        })
      }
    },
  },

  TRIGGER_RELOAD_PLANS: {
    handler: ({ app, jsonPayload }) => {
      const { projectId } = jsonPayload
      const store = app.config.globalProperties.$store

      if (projectId !== store.state.project.project._id) {
        throw new Error('ProjectId of TRIGGER_RELOAD_PLANS event does not match with currently loaded project')
      }

      store.dispatch('plans/fetch', projectId)
    },
  },

  TRIGGER_RELOAD_DOCUMENTS: {
    handler: ({ app, jsonPayload }) => {
      const { projectId } = jsonPayload
      const store = app.config.globalProperties.$store

      if (projectId !== store.state.project.project._id) {
        throw new Error('ProjectId of TRIGGER_RELOAD_DOCUMENTS event does not match with currently loaded project')
      }

      store.dispatch('documents/fetch', projectId)
    },
  },

  TRIGGER_RELOAD_TASKS: {
    handler: ({ app, jsonPayload }) => {
      const { projectId } = jsonPayload
      const store = app.config.globalProperties.$store

      if (projectId !== store.state.project.project._id) {
        throw new Error('ProjectId of TRIGGER_RELOAD_TASKS event does not match with currently loaded project')
      }

      store.dispatch('tasks/fetch', {
        projectId,
      })
    },
  },

  TRIGGER_RELOAD_PROJECT: {
    handler: ({ app, jsonPayload }) => {
      const { projectId } = jsonPayload
      const store = app.config.globalProperties.$store

      if (projectId !== store.state.project.project._id) {
        throw new Error('ProjectId of TRIGGER_RELOAD_PROJECT event does not match with currently loaded project')
      }

      store.dispatch('project/fetch', projectId)
    },
  },

  TRIGGER_RELOAD_LOCATION_LAYERS: {
    handler: ({ app, jsonPayload }) => {
      const { projectId } = jsonPayload
      const store = app.config.globalProperties.$store

      if (projectId !== store.state.project.project._id) {
        throw new Error('ProjectId of TRIGGER_RELOAD_LOCATION_LAYERS event does not match with currently loaded project')
      }

      store.dispatch('locationLayers/fetch', { projectId })
    },
  },

  TRIGGER_RELOAD_FILTERS: {
    handler: ({ app, jsonPayload }) => {
      const { projectId } = jsonPayload
      const store = app.config.globalProperties.$store

      if (projectId !== store.state.project.project._id) {
        throw new Error('ProjectId of TRIGGER_RELOAD_FILTERS event does not match with currently loaded project')
      }

      store.dispatch('project/fetchFilters', { projectId })
    },
  },

  LOCATION_LAYER_NEW: {
    getResourceId: ({ jsonPayload }) => {
      const { locationLayer } = jsonPayload

      return locationLayer._id
    },
    handler: ({ app, jsonPayload }) => {
      const { projectId, locationLayer } = jsonPayload
      const store = app.config.globalProperties.$store

      if (projectId !== store.state.project.project._id) {
        throw new Error('ProjectId of LOCATION_LAYER_NEW event does not match with currently loaded project')
      }

      store.commit('locationLayers/ADD_LOCATION_LAYER', new LocationLayer(locationLayer))
    },
  },

  LOCATION_LAYER_UPDATE: {
    getResourceId: ({ jsonPayload }) => {
      const { locationLayer } = jsonPayload

      return locationLayer._id
    },
    handler: ({ app, jsonPayload }) => {
      const { projectId, locationLayer } = jsonPayload
      const store = app.config.globalProperties.$store

      if (projectId !== store.state.project.project._id) {
        throw new Error('ProjectId of LOCATION_LAYER_UPDATE event does not match with currently loaded project')
      }

      const originalLocationLayer = store.getters['locationLayers/getById'](locationLayer._id)

      if (!originalLocationLayer) {
        console.error(`Original locationLayer with _id "${locationLayer._id}" couldn't be found`)
        return
      }

      store.commit('locationLayers/REPLACE_LOCATION_LAYER', new LocationLayer(locationLayer))
    },
  },
}

// function logPopupContextUpdate ({ app, socketAction, socketPayload, updatedContextId }) {
//   const store = app.config.globalProperties.$store
//   const logger = app.config.globalProperties.$logger

//   const firstPopupWithContext = store.state.layout.openPopups.find(popup => popup.lockedIds.includes(updatedContextId))

//   if (!firstPopupWithContext) {
//     return
//   }

//   logger.log({
//     tag: 'POPUP_CONTEXT_UPDATED_VIA_SOCKET',
//     context: {
//       socketAction,
//       socketPayload,
//       updatedContextId,
//       openPopups: store.state.layout.openPopups.map(popup => {
//         return {
//           popupId: popup.popupId,
//           popupType: popup.popupType,
//           lockedIds: popup.lockedIds,
//           componentName: popup.componentName,
//         }
//       }),
//     },
//   })
// }

// function logListReload ({ app, socketAction, projectId }) {
//   const store = app.config.globalProperties.$store
//   const logger = app.config.globalProperties.$logger

//   const hasOpenPopup = store.state.layout.openPopups.length > 0

//   if (!hasOpenPopup) {
//     return
//   }

//   logger.log({
//     tag: 'TRIGGER_RELOAD_VIA_SOCKET',
//     context: {
//       socketAction,
//       socketPayload: {
//         projectId,
//       },
//       openPopups: store.state.layout.openPopups.map(popup => {
//         return {
//           popupId: popup.popupId,
//           popupType: popup.popupType,
//           lockedIds: popup.lockedIds,
//           componentName: popup.componentName,
//         }
//       }),
//     },
//   })
// }

export default {
  install: (app) => {
    let ioConnection
    let isConnected = false

    const socket = {
      connect (projectId) {
        if (isConnected || app.config.globalProperties.$config.isBackupApp) {
          return
        }

        if (!projectId) {
          console.error('No projectId passed to connect to websocket')
          return
        }

        ioConnection = io.connect(app.config.globalProperties.$config.api.websocketServerUrl, {
          transports: ['websocket'],
          auth: {
            token: app.config.globalProperties.$auth.accessToken,
            projectId,
          },
        })

        ioConnection.on('connect', () => {
          isConnected = true
        })

        ioConnection.on('disconnect', () => {
          isConnected = false
        })

        ioConnection.onAny((eventName, payload) => {
          // If the eventhandler has a resourceId, check if there is any popup open with this resourceid. If yes, push the update into a queue and don't apply it directly. If no, apply the update directly.
          // If the eventhandler has no resourceId (e.g. TRIGGER_RELOAD_PLANS, TRIGGER_RELOAD_DOCUMENTS, TRIGGER_RELOAD_TASKS, TRIGGER_RELOAD_PROJECT, TRIGGER_RELOAD_LOCATION_LAYERS, TRIGGER_RELOAD_FILTERS) we push the event to the queue if a popup is open

          const handler = socketHandlers[eventName]
          const jsonPayload = JSON.parse(payload)

          if (!handler) {
            console.warn(`No handler found for event: ${eventName}`)
            return
          }

          const resourceId = handler.getResourceId ? handler.getResourceId({ jsonPayload }) : undefined
          const store = app.config.globalProperties.$store
          const hasLockedContext = resourceId && store.getters['layout/getLockedIds'].includes(resourceId)
          const hasOpenPopup = store.state.layout.openPopups.length > 0

          if (hasLockedContext || (!resourceId && hasOpenPopup)) {
            // Remove old event from queue and push new one to the end of the queue. Otherwise there might be cases where old events override a new state
            queuedEvents = queuedEvents.filter(event => {
              if (resourceId) {
                return event.resourceId !== resourceId && event.eventName !== eventName
              }

              return !event.resourceId && event.eventName !== eventName
            })

            queuedEvents.push({
              id: generateRandomId(),
              eventName,
              resourceId,
              jsonPayload,
            })
          } else {
            handler.handler({ app, jsonPayload })
          }
        })
      },

      disconnect () {
        if (!isConnected) {
          return
        }

        ioConnection.disconnect()
      },

      getConnection () {
        return ioConnection
      },

      applyQueueUpdates () {
        // A popup (modal, overlay) has a context, like a taskId. If a popup with a specific contextId is open, and a websocket message with this exact contextId appears, we don't apply the update directly. Instead we push it into a queue and make the update as soon as the popup got closed. This way we prevent modals to be closed unexpectedly, because of a disappearing list-item caused by a filter result set change.

        for (const event of queuedEvents) {
          const handler = socketHandlers[event.eventName]
          const store = app.config.globalProperties.$store
          const hasLockedContext = event.resourceId && store.getters['layout/getLockedIds'].includes(event.resourceId)
          const hasOpenPopup = store.state.layout.openPopups.length > 0

          if (hasLockedContext || (!event.resourceId && hasOpenPopup)) {
            continue
          }

          handler.handler({ app, jsonPayload: event.jsonPayload })

          queuedEvents = queuedEvents.filter(e => e.id !== event.id)
        }
      },
    }

    app.config.globalProperties.$socket = socket
  },
}
