import { getLatestCreatedDocument } from '@client-shared/api/documents.api'

export default {
  namespaced: true,

  state: () => {
    return {
      isEnabled: false,
      isOpen: false,
      latestCreatedItemByUser: undefined,

      features: {
        useAuthor: true,
        usePermission: true,
        usePhasetag: true,
        useCategory: true,
        useTags: true,
        useIndex: true,
        useIndexComment: true,
        useApproval: true,
        useApprovalRequestMessage: true,
        useApprovalDate: true,
      },
    }
  },

  getters: {
    activeFeatureCount: (state, getters, rootState, rootGetters) => {
      const isAdminOrOwner = rootGetters['acl/isAdminOrOwner']

      return Object.keys(state.features)
        .filter(featureKey => {
          if (featureKey === 'useAuthor') {
            return isAdminOrOwner
          }

          if (featureKey === 'useCategory') {
            return rootState.project.project.topCategoriesDocumentsEnabled
          }

          if (featureKey === 'useApprovalDate') {
            return state.features.useApproval
          }

          if (featureKey === 'useApprovalRequestMessage') {
            return state.features.useApproval
          }

          return true
        })
        .filter(featureKey => state.features[featureKey])
        .length
    },
  },

  mutations: {
    SET_LATEST_CREATED_ITEM_BY_USER (state, item) {
      state.latestCreatedItemByUser = item
    },

    SET_IS_ENABLED (state, isEnabled) {
      state.isEnabled = isEnabled
    },

    TOGGLE_IS_ENABLED (state) {
      state.isEnabled = !state.isEnabled
    },

    TOGGLE_IS_OPEN (state) {
      state.isOpen = !state.isOpen
    },

    SET_FEATURE_AUTHOR (state, value) {
      state.features.useAuthor = value
    },

    SET_FEATURE_PERMISSION (state, value) {
      state.features.usePermission = value
    },

    SET_FEATURE_CATEGORY (state, value) {
      state.features.useCategory = value
    },

    SET_FEATURE_PHASETAG (state, value) {
      state.features.usePhasetag = value
    },

    SET_FEATURE_TAGS (state, value) {
      state.features.useTags = value
    },

    SET_FEATURE_INDEX (state, value) {
      state.features.useIndex = value
    },

    SET_FEATURE_INDEX_COMMENT (state, value) {
      state.features.useIndexComment = value
    },

    SET_FEATURE_APPROVAL (state, value) {
      state.features.useApproval = value
    },

    SET_FEATURE_APPROVAL_MESSAGE (state, value) {
      state.features.useApprovalRequestMessage = value
    },

    SET_FEATURE_APPROVAL_DATE (state, value) {
      state.features.useApprovalDate = value
    },
  },

  actions: {
    async fetchLatestCreatedItemByUser ({ commit }, { projectId }) {
      const item = await getLatestCreatedDocument({
        axios: this.$axios,
        projectId,
      })

      commit('SET_LATEST_CREATED_ITEM_BY_USER', item)
    },

  },
}
