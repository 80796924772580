import filterFunctions from '@client-shared/utils/filter-functions'

import constants from '@/config/constants'
import sendRaygun from '@/utils/send-raygun.js'

const getPropertyValue = ({ currentValue, hasResult }) => {
  return {
    count: currentValue ? currentValue.count + 1 : 1,
    hasResult,
  }
}

const setHasResult = (object, value) => {
  for (const key of Object.keys(object)) {
    object[key].hasResult = value
  }
}

const handleApprovalDatePresetCount = ({ result, item }) => {
  for (const preset of Object.values(constants.VALID_ITEM_APPROVAL_DATE_PRESETS)) {
    const matchesPreset = filterFunctions.items.matchesFilterApprovalDatePreset({
      approvalDatePreset: preset,
      item,
    })

    if (matchesPreset) {
      result.approvalDatePresets[preset] = getPropertyValue({
        currentValue: result.approvalDatePresets[preset],
        hasResult: false,
      })
    }
  }
}

const handleDocumentPermissionsCount = ({ result, item }) => {
  if (item.permission.restricted) {
    result.permissions[constants.DOCUMENT_PERMISSIONS.RESTRICTED] = getPropertyValue({
      currentValue: result.permissions[constants.DOCUMENT_PERMISSIONS.RESTRICTED],
      hasResult: false,
    })
  } else {
    result.permissions[constants.DOCUMENT_PERMISSIONS.PUBLIC] = getPropertyValue({
      currentValue: result.permissions[constants.DOCUMENT_PERMISSIONS.PUBLIC],
      hasResult: false,
    })
  }

  for (const participantId of item.permission.users) {
    result.permissionUserIds[participantId] = getPropertyValue({
      currentValue: result.permissionUserIds[participantId],
      hasResult: false,
    })
  }
}

const handleTopCategoriesCount = ({ result, itemType, item, project }) => {
  if (itemType === 'plans' && !project.topCategoriesPlansEnabled) {
    return
  }

  if (itemType === 'documents' && !project.topCategoriesDocumentsEnabled) {
    return
  }

  result.topCategories[item.topCategory] = getPropertyValue({
    currentValue: result.topCategories[item.topCategory],
    hasResult: false,
  })
}

const handlePhasetagsCount = ({ result, item }) => {
  result.phasetags[item.phasetag] = getPropertyValue({
    currentValue: result.phasetags[item.phasetag],
    hasResult: false,
  })
}

const handleAuthorCount = ({ result, project, item }) => {
  const author = project.findParticipantByPlanDocAuthor(item.author)

  // temporary raygun logging for debugging - can be removed by end of 2024
  if (!author) {
    sendRaygun({
      error: new Error('PROPERTY_ACCESS_ERROR: author.roles[0]'),
      tags: ['property_access_error'],
      customData: {
        author: item.author,
        project: project._id,
      },
    })

    return
  }

  result.authorRoles[author.roles[0]] = getPropertyValue({
    currentValue: result.authorRoles[author.roles[0]],
    hasResult: false,
  })

  if (item.author.userId) {
    result.authorUserIds[item.author.userId] = getPropertyValue({
      currentValue: result.authorUserIds[item.author.userId],
      hasResult: false,
    })
  }
}

const handleTagsCount = ({ result, item }) => {
  for (const tag of item.tags) {
    result.tags[tag] = getPropertyValue({
      currentValue: result.tags[tag],
      hasResult: false,
    })
  }
}

const handleApproversCount = ({ result, item, project }) => {
  const approvers = item.computed.latestRevision.approval.by.map(by => project.findParticipantById(by.participantid))

  const addedRoles = []

  for (const approver of approvers) {
    const userId = approver.user?._id || approver.user

    result.approverUserIds[userId] = getPropertyValue({
      currentValue: result.approverUserIds[userId],
      hasResult: false,
    })

    if (!addedRoles.includes(approver.roles[0])) {
      result.approverRoles[approver.roles[0]] = getPropertyValue({
        currentValue: result.approverRoles[approver.roles[0]],
        hasResult: false,
      })

      addedRoles.push(approver.roles[0])
    }
  }
}

const handleApprovalsCount = ({ result, item }) => {
  const approvalStatus = item.computed.latestRevision.approvalStatus

  const hasRejectedApprovers = item.computed.latestRevision.approval.by.find(approver => approver.approved === 'rejected')

  if (approvalStatus !== 'rejected' && hasRejectedApprovers) {
    result.approvals.rejected = getPropertyValue({
      currentValue: result.approvals.rejected,
      hasResult: false,
    })
  }

  if (approvalStatus === 'approved' || approvalStatus === 'approvedViaImport') {
    result.approvals.approved = getPropertyValue({
      currentValue: result.approvals.approved,
      hasResult: false,
    })
  } else if (approvalStatus === 'rejected') {
    result.approvals.rejected = getPropertyValue({
      currentValue: result.approvals.rejected,
      hasResult: false,
    })
  } else if (approvalStatus === 'notrequested') {
    result.approvals.notrequested = getPropertyValue({
      currentValue: result.approvals.notrequested,
      hasResult: false,
    })
  } else if (approvalStatus === 'pending') {
    result.approvals.pending = getPropertyValue({
      currentValue: result.approvals.pending,
      hasResult: false,
    })
  }
}

const handleTopCategoriesHasResults = ({ result, itemType, project, filterStore, itemsAll, isFilterActive }) => {
  let isTopCategoriesEnabled = false

  if (itemType === 'plans' && project.topCategoriesPlansEnabled) {
    isTopCategoriesEnabled = true
  }

  if (itemType === 'documents' && project.topCategoriesDocumentsEnabled) {
    isTopCategoriesEnabled = true
  }

  if (!isTopCategoriesEnabled) {
    return
  }

  if (isFilterActive) {
    const itemsFilteredExcludingTopCategory = itemsAll.filter(item => { return filterFunctions[itemType].matchesFilters({ item, project, ...filterStore, topCategories: [] }) })

    for (const item of itemsFilteredExcludingTopCategory) {
      result.topCategories[item.topCategory].hasResult = true
    }
  } else {
    setHasResult(result.topCategories, true)
  }
}

const handlePhasetagsHasResults = ({ result, itemType, project, filterStore, itemsAll, isFilterActive }) => {
  if (isFilterActive) {
    const itemsFilteredExcludingPhasetag = itemsAll.filter(item => { return filterFunctions[itemType].matchesFilters({ item, project, ...filterStore, phasetags: [] }) })

    for (const item of itemsFilteredExcludingPhasetag) {
      result.phasetags[item.phasetag] = result.phasetags[item.phasetag] || { hasResult: false, count: 0 }
      result.phasetags[item.phasetag].hasResult = true
    }
  } else {
    setHasResult(result.phasetags, true)
  }
}

const handleAuthorHasResults = ({ result, itemType, project, filterStore, itemsAll, isFilterActive }) => {
  if (isFilterActive) {
    const itemsFilteredExcludingAuthor = itemsAll.filter(item => { return filterFunctions[itemType].matchesFilters({ item, project, ...filterStore, authorRoles: [], authorUserIds: [] }) })

    for (const item of itemsFilteredExcludingAuthor) {
      const author = project.findParticipantByPlanDocAuthor(item.author)

      result.authorRoles[author.roles[0]].hasResult = true

      if (item.author.userId) {
        result.authorUserIds[item.author.userId].hasResult = true
      }
    }
  } else {
    setHasResult(result.authorRoles, true)
    setHasResult(result.authorUserIds, true)
  }
}

const handleTagsHasResults = ({ result, itemType, project, filterStore, itemsAll, itemsFiltered, isFilterActive }) => {
  if (isFilterActive) {
    let itemsFilteredExcludingTags

    if (filterStore.tagsAndSwitchActive) {
      itemsFilteredExcludingTags = itemsFiltered
    } else {
      itemsFilteredExcludingTags = itemsAll.filter(item => { return filterFunctions[itemType].matchesFilters({ item, project, ...filterStore, tags: [] }) })
    }

    for (const item of itemsFilteredExcludingTags) {
      for (const tag of item.tags) {
        result.tags[tag] = result.tags[tag] || { hasResult: false, count: 0 }
        result.tags[tag].hasResult = true
      }
    }
  } else {
    setHasResult(result.tags, true)
  }
}

const handleApprovalHasResults = ({ result, itemType, project, filterStore, itemsAll, isFilterActive }) => {
  if (isFilterActive) {
    const itemsFilteredExcludingApprovals = itemsAll.filter(item => { return filterFunctions[itemType].matchesFilters({ item, project, ...filterStore, approvals: [] }) })

    for (const item of itemsFilteredExcludingApprovals) {
      const approvalStatus = item.computed.latestRevision.approvalStatus
      const hasRejectedApprovers = item.computed.latestRevision.approval.by.find(approver => approver.approved === 'rejected')

      if (hasRejectedApprovers) {
        result.approvals.rejected.hasResult = true
      }

      if (approvalStatus === 'approved' || approvalStatus === 'approvedViaImport') {
        result.approvals.approved.hasResult = true
      } else if (approvalStatus === 'rejected') {
        result.approvals.rejected.hasResult = true
      } else if (approvalStatus === 'notrequested') {
        result.approvals.notrequested.hasResult = true
      } else if (approvalStatus === 'pending') {
        result.approvals.pending.hasResult = true
      }
    }
  } else {
    setHasResult(result.approvals, true)
  }
}

const handleApproversHasResults = ({ result, itemType, project, filterStore, itemsAll, isFilterActive }) => {
  if (isFilterActive) {
    const itemsFilteredExcludingApprovers = itemsAll.filter(item => { return filterFunctions[itemType].matchesFilters({ item, project, ...filterStore, approverUserIds: [], approverRoles: [] }) })

    for (const item of itemsFilteredExcludingApprovers) {
      const approvers = item.computed.latestRevision.approval.by.map(by => project.findParticipantById(by.participantid))

      for (const approver of approvers) {
        const userId = approver.user._id || approver.user

        result.approverUserIds[userId] = result.approverUserIds[userId] || { hasResult: false, count: 0 }
        result.approverUserIds[userId].hasResult = true
        result.approverRoles[approver.roles[0]].hasResult = true
      }
    }
  } else {
    setHasResult(result.approverRoles, true)
    setHasResult(result.approverUserIds, true)
  }
}

const handleDocumentPermissionsHasResults = ({ result, itemType, project, filterStore, itemsAll, isFilterActive }) => {
  if (isFilterActive) {
    const itemsFilteredExcludingPermissions = itemsAll.filter(item => {
      return filterFunctions[itemType].matchesFilters({
        item,
        project,
        ...filterStore,
        permissions: [],
        permissionUserIds: [],
      })
    })

    for (const item of itemsFilteredExcludingPermissions) {
      if (item.permission.restricted) {
        result.permissions[constants.DOCUMENT_PERMISSIONS.RESTRICTED].hasResult = true
      } else {
        result.permissions[constants.DOCUMENT_PERMISSIONS.PUBLIC].hasResult = true
      }

      for (const userId of item.permission.users) {
        result.permissionUserIds[userId] = result.permissionUserIds[userId] || { hasResult: false, count: 0 }
        result.permissionUserIds[userId].hasResult = true
      }
    }
  } else {
    setHasResult(result.permissions, true)
    setHasResult(result.permissionUserIds, true)
  }
}

const handleApprovalDateHasResults = ({ result, itemType, project, filterStore, itemsAll, isFilterActive }) => {
  if (isFilterActive) {
    const itemsFilteredExcludingApprovalDate = itemsAll.filter(item => { return filterFunctions[itemType].matchesFilters({ item, project, ...filterStore, approvalDateFrom: undefined, approvalDateTo: undefined, approvalDatePreset: undefined }) })

    for (const item of itemsFilteredExcludingApprovalDate) {
      for (const preset of Object.values(constants.VALID_ITEM_APPROVAL_DATE_PRESETS)) {
        const matchesPreset = filterFunctions.items.matchesFilterApprovalDatePreset({
          approvalDatePreset: preset,
          item,
        })

        if (matchesPreset) {
          result.approvalDatePresets[preset].hasResult = true
        }
      }
    }
  } else {
    setHasResult(result.approvalDatePresets, true)
  }
}

export const getResult = ({ project, itemsAll, itemsFiltered, itemType, filterStore }) => {
  const result = {
    topCategories: {},
    authorRoles: {},
    authorUserIds: {},
    phasetags: {},
    tags: {},
    approverRoles: {},
    approverUserIds: {},
    permissions: {},
    permissionUserIds: {},
    approvals: {},
    approvalDatePresets: {},
  }

  const isFilterActive = itemsAll.length !== itemsFiltered.length

  for (const item of itemsAll) {
    handleTopCategoriesCount({ result, itemType, item, project })
    handlePhasetagsCount({ result, item })
    handleAuthorCount({ result, item, project })
    handleTagsCount({ result, item })
    handleApprovalsCount({ result, item })
    handleApproversCount({ result, item, project })
    handleApprovalDatePresetCount({ result, item, project })

    if (itemType === 'documents') {
      handleDocumentPermissionsCount({ result, item })
    }
  }

  handleTopCategoriesHasResults({ result, itemType, project, isFilterActive, itemsAll, filterStore })
  handlePhasetagsHasResults({ result, itemType, project, isFilterActive, itemsAll, filterStore })
  handleAuthorHasResults({ result, itemType, project, isFilterActive, itemsAll, filterStore })
  handleTagsHasResults({ result, itemType, project, isFilterActive, itemsAll, itemsFiltered, filterStore })
  handleApprovalHasResults({ result, itemType, project, isFilterActive, itemsAll, filterStore })
  handleApproversHasResults({ result, itemType, project, isFilterActive, itemsAll, filterStore })
  handleApprovalDateHasResults({ result, itemType, project, isFilterActive, itemsAll, filterStore })

  if (itemType === 'documents') {
    handleDocumentPermissionsHasResults({ result, itemType, project, isFilterActive, itemsAll, filterStore })
  }

  return result
}
