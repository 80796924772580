import * as DocumentsApi from '@client-shared/api/documents.api.js'
import filterFunctions from '@client-shared/utils/filter-functions'

import constants from '@/config/constants.js'

export default {
  namespaced: true,

  state: () => {
    return {
      list: [],
      markedItem: null,
      selectedMultiActionProperty: constants.MULTI_ACTION.TOP_CATEGORY,

      sortSettings: {
        sortProperty: constants.SORT_PROPERTIES.ITEM_NAME,
        sortOrderReversed: false,
      },

      printSettings: {
        showComments: false,
        showPermissionListNames: false,
        groupBy: constants.ITEM_PRINT_SETTINGS.GROUP_BY_NONE,
        showItemUrl: false,
      },
    }
  },

  getters: {
    getById: (state) => {
      return documentId => state.list.find(document => document._id === documentId)
    },

    getByName: (state) => {
      return documentName => { return state.list.filter(document => document.name === documentName) }
    },

    trashedItems: (state) => {
      return state.list.filter(p => p.trashed)
    },

    listFilteredByModus: (state, getters, rootState, rootGetters) => {
      const modus = rootState.documentsListModus.modus

      if (modus === constants.LIST_MODUS.APPROVAL) {
        const documentsNotTrashed = state.list.filter(p => !p.trashed)
        const project = rootState.project.project
        const currentUserParticipant = project.findParticipantByUserId(rootState.currentUser.user._id)

        if (!currentUserParticipant) {
          return documentsNotTrashed
        }

        return documentsNotTrashed.filter(p => {
          const documentApproval = p.computed.latestRevision.approval

          if (!documentApproval || !documentApproval.by) {
            return false
          }

          const approver = p.computed.latestRevision.approval.by.find(by => by.participantid === currentUserParticipant._id)

          if (approver && approver.approved === 'pending') {
            return true
          }

          return false
        })
      } else if (modus === constants.LIST_MODUS.TRASH) {
        return getters.trashedItems
      } else if (modus === constants.LIST_MODUS.LIST || modus === constants.LIST_MODUS.PRINT || modus === constants.LIST_MODUS.MULTI_ACTION) {
        return state.list.filter(document => !document.trashed)
      } else if (modus === constants.LIST_MODUS.UPDATE || modus === constants.LIST_MODUS.MULTI_ACTION_EDIT) {
        return state.list.filter(document => {
          return !document.trashed && rootGetters['acl/isAllowedToUpdatePlanDoc'](document)
        })
      }
      return []
    },

    listFiltered: (state, getters, rootState) => {
      return getters.listFilteredByModus.filter(document => filterFunctions.documents.matchesFilters({
        project: rootState.project.project,
        item: document,
        ...rootState[rootState.documentsListModus.filterStoreName],
      }))
    },
  },

  mutations: {
    SET_DOCUMENTS (state, documents) {
      state.list = documents
    },

    ADD_DOCUMENT (state, document) {
      const existingDocumentIndex = state.list.findIndex(d => d._id === document._id)

      if (existingDocumentIndex !== -1) {
        const isUpdateNewer = !state.list[existingDocumentIndex].modified || new Date(document.modified) > new Date(state.list[existingDocumentIndex].modified) // Old documents do not have a modified date. We introduce it 2025

        if (isUpdateNewer) {
          state.list[existingDocumentIndex] = document
        }
      } else {
        state.list.push(document)
      }
    },

    REPLACE_DOCUMENT (state, updatedDocument) {
      const existingDocumentIndex = state.list.findIndex(d => d._id === updatedDocument._id)

      if (existingDocumentIndex !== -1) {
        const isUpdateNewer = !state.list[existingDocumentIndex].modified || new Date(updatedDocument.modified) > new Date(state.list[existingDocumentIndex].modified) // Old documents do not have a modified date. We introduce it 2025

        if (isUpdateNewer) {
          state.list[existingDocumentIndex] = updatedDocument
        }
      }
    },

    REMOVE_DOCUMENT (state, documentId) {
      state.list = state.list.filter(document => document._id !== documentId)
    },

    RESET_DOCUMENTS (state) {
      state.list = []
    },

    FULL_RESET (state) {
      state.list = []
      state.markedItem = null

      state.sortSettings = {
        sortProperty: constants.SORT_PROPERTIES.ITEM_NAME,
        sortOrderReversed: false,
      }

      state.printSettings = {
        showComments: false,
        showPermissionListNames: false,
        groupBy: constants.ITEM_PRINT_SETTINGS.GROUP_BY_NONE,
        showItemUrl: false,
      }
    },

    SET_MARKED_ITEM (state, item) {
      state.markedItem = item
    },

    RESET_SORTING (state, item) {
      state.sortSettings.sortProperty = constants.SORT_PROPERTIES.ITEM_NAME
      state.sortSettings.sortOrderReversed = false
    },

    SET_SORT_PROPERTY (state, sortProperty) {
      state.sortSettings.sortProperty = sortProperty
    },

    SET_SORT_ORDER_REVERSED (state, sortOrderReversed) {
      state.sortSettings.sortOrderReversed = sortOrderReversed
    },

    SET_GROUP_BY (state, groupBy) {
      const validModi = [
        constants.ITEM_PRINT_SETTINGS.GROUP_BY_TOP_CATEGORIES,
        constants.ITEM_PRINT_SETTINGS.GROUP_BY_ROLES,
        constants.ITEM_PRINT_SETTINGS.GROUP_BY_PHASETAGS,
        constants.ITEM_PRINT_SETTINGS.GROUP_BY_NONE,
      ]

      if (!validModi.includes(groupBy)) {
        console.error(`${groupBy} is not a valid grouping mode for documents list`)
        return
      }
      state.printSettings.groupBy = groupBy
    },

    SET_SHOW_ITEM_URL (state, newValue) {
      state.printSettings.showItemUrl = newValue
    },

    SET_SHOW_COMMENTS (state, newValue) {
      state.printSettings.showComments = newValue
    },

    SET_SHOW_PERMISSION_LIST_NAMES (state, newValue) {
      state.printSettings.showPermissionListNames = newValue
    },

    SET_SELECTED_MULTI_ACTION_PROPERTY (state, multiActionProperty) {
      state.selectedMultiActionProperty = multiActionProperty
    },
  },

  actions: {
    async fetch ({ commit }, projectId) {
      const documents = await DocumentsApi.getDocuments({
        axios: this.$axios,
        projectId,
      })

      commit('SET_DOCUMENTS', documents)
    },

    async fetchById ({ commit }, documentId) {
      const document = await DocumentsApi.getDocument({
        axios: this.$axios,
        documentId,
      })

      commit('ADD_DOCUMENT', document)
    },

    async updateDocumentTags ({ commit, dispatch }, { item, newValue }) {
      const updatedDocument = await DocumentsApi.updateTags({
        axios: this.$axios,
        document: item,
        newValue,
      })
      await dispatch('project/fetch', item.projectid, { root: true })

      commit('REPLACE_DOCUMENT', updatedDocument)
    },

    async updateDocumentTopCategory ({ commit }, { item, newTopCategory }) {
      const updatedDocument = await DocumentsApi.updateTopCategory({
        axios: this.$axios,
        document: item,
        newTopCategory,
      })

      commit('REPLACE_DOCUMENT', updatedDocument)
    },

    async updateDocumentPermissionDownloadLinks ({ commit }, { item, permissionDownloadLinks }) {
      const updatedDocument = await DocumentsApi.updatePermissionDownloadLinks({
        axios: this.$axios,
        document: item,
        permissionDownloadLinks,
      })

      commit('REPLACE_DOCUMENT', updatedDocument)
    },

    async updateDocumentPhasetag ({ commit }, { item, newPhasetag }) {
      const updatedDocument = await DocumentsApi.updatePhasetag({
        axios: this.$axios,
        document: item,
        newPhasetag,
      })

      commit('REPLACE_DOCUMENT', updatedDocument)
    },

    async addDocumentRevision ({ commit }, { item, revision }) {
      const updatedDocument = await DocumentsApi.addRevision({
        axios: this.$axios,
        document: item,
        revision,
      })

      commit('REPLACE_DOCUMENT', updatedDocument)
    },

    async updateDocumentTrashed ({ commit, dispatch }, { item, newTrashedValue }) {
      const updatedDocument = await DocumentsApi.updateTrashed({
        axios: this.$axios,
        document: item,
        newTrashedValue,
      })

      commit('REPLACE_DOCUMENT', updatedDocument)
      dispatch('project/fetch', this.state.project.project._id, { root: true })
    },

    async updateRevisionApprovalStatus ({ commit, dispatch }, { item, approver, approvalStatus, message, attachments }) {
      const updatedDocument = await DocumentsApi.updateRevisionApprovalStatus({
        axios: this.$axios,
        document: item,
        approver,
        approvalStatus,
        message,
        attachments,
      })

      commit('REPLACE_DOCUMENT', updatedDocument)
      dispatch('project/fetch', this.state.project.project._id, { root: true })
    },

    async addRevisionApprovalComment ({ commit, rootState }, { item, approver, message, attachments }) {
      const updatedDocument = await DocumentsApi.addRevisionApprovalComment({
        axios: this.$axios,
        document: item,
        approver,
        message,
        attachments,
      })

      commit('REPLACE_DOCUMENT', updatedDocument)
    },

    async updateDocumentRevisionApproval ({ commit }, { item, revision, newParticipantIds, newApprovalMode, newApprovalRequestMessage, newApprovalDate }) {
      const updatedDocument = await DocumentsApi.updateRevisionApproval({
        axios: this.$axios,
        document: item,
        revision,
        newParticipantIds,
        newApprovalMode,
        newApprovalRequestMessage,
        newApprovalDate,
      })

      commit('REPLACE_DOCUMENT', updatedDocument)
    },

    async approveRevisionByMyself ({ commit }, { item, revision, comment }) {
      const updatedDocument = await DocumentsApi.approveRevisionByMyself({
        axios: this.$axios,
        document: item,
        revision,
        comment,
      })

      commit('REPLACE_DOCUMENT', updatedDocument)
    },

    async rejectRevisionByMyself ({ commit }, { item, revision, comment }) {
      const updatedDocument = await DocumentsApi.rejectRevisionByMyself({
        axios: this.$axios,
        document: item,
        revision,
        comment,
      })

      commit('REPLACE_DOCUMENT', updatedDocument)
    },

    async updateDocumentAuthor ({ commit }, { item, newUserId, newRole }) {
      const updatedDocument = await DocumentsApi.updateAuthor({
        axios: this.$axios,
        document: item,
        newUserId,
        newRole,
      })

      commit('REPLACE_DOCUMENT', updatedDocument)
    },

    async updateDocumentName ({ commit }, { item, newName, newContent }) {
      const updatedDocument = await DocumentsApi.updateName({
        axios: this.$axios,
        document: item,
        newName,
        newContent,
      })

      commit('REPLACE_DOCUMENT', updatedDocument)
    },

    async updatePermission ({ commit }, { item, newRestricted, newUsers }) {
      const updatedDocument = await DocumentsApi.updatePermission({
        axios: this.$axios,
        document: item,
        newRestricted,
        newUsers,
      })

      commit('REPLACE_DOCUMENT', updatedDocument)
    },
    async processMultiUpdateResults ({ commit, dispatch }, { results, projectId }) {
      const fulfilled = results.filter(r => r.status === 'fulfilled')
      if (fulfilled.length) {
        fulfilled.forEach(r => {
          const updatedDocument = r.value

          commit('REPLACE_DOCUMENT', updatedDocument)
        })

        await dispatch('project/fetch', projectId, { root: true })
      }

      if (results.some(r => r.status === 'rejected')) {
        return Promise.reject(new Error('UPDATES_FAILED'))
      }
    },
  },
}
