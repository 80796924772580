<template>
  <PfModal
    ref="modal"
    tabindex="0"
    :locked-ids="[]"
    @after:close-animation="$emit('after:close-animation')"
  >
    <div
      class="com_modal__window-header"
    >
      <div
        class="com_modal__window-title"
      >
        {{ $t('layout.header.nav.help') }}
      </div>
    </div>

    <div
      class="com_modal__window-body com_modal__window-body--no-padding com_modal__window-body--shaded"
    >
      <div
        class="layout-support-box"
      >
        <div>
          <h4>
            {{ $t('feature.support_bar.support_box.title') }}
          </h4>

          <div style="display: inline-flex; flex-flow: column nowrap; gap:var(--spacing-sm, 0.750rem)">
            <a
              v-for="link in supportLinks"
              :key="link.key"
              :data-testid="`support-box-link-${link.key}`"
              :href="link.href"
              target="_blank"
              class="obj_featured-link obj--lg"
              @click="onClickGotoSupportLink(link)"
            >
              <PfIcon
                :name="link.iconName"
                :is-circle="true"
                size="2xl"
                class="obj_featured-link__icon"
              />

              <span
                class="obj_featured-link__label"
              >
                {{ link.label }}
              </span>
            </a>

            <div
              v-if="!$config.isDemoserver"
              class="uti_margin-top-lg obj_footnote"
              data-testid="prime-links"
            >
              <p v-html="$t('feature.support_bar.prime_links')" />
            </div>
          </div>
        </div>

        <div
          v-if="$config.isDemoserver"
          data-testid="support-bar-mail"
        >
          <h4>
            {{ $t('feature.support_bar.support_form.title') }}
          </h4>

          <a
            data-testid="support-bar-mail-link"
            class="com_form__button    obj_button"
            :href="`mailto:support@planfred.com?subject=${$t('feature.support_bar.subject')}`"
          >
            <PfIcon
              class="icon"
              name="email"
            />

            <span class="label">
              {{ $t('feature.support_bar.send_email') }}
            </span>
          </a>
        </div>

        <div
          v-if="!$config.isDemoserver"
          data-testid="support-bar-form"
        >
          <div
            v-if="state !== validStates.SUBMIT_SUCCESS"
            class="com_form"
          >
            <h4>
              {{ $t('feature.support_bar.support_form.title') }}
            </h4>

            <div
              class="com_form__group"
            >
              <RichTextEditor
                v-model="form.text"
                data-testid="support-message-editor"
                upload-type="support"
                :model-value="setSupportMessage"
                @on-change="onHelpRequest"
              />

              <MessageBoxWarning
                v-if="v$.form.text.$error"
                data-testid="error-message-required"
                :message="$t('feature.support_bar.support_form.message_required')"
                :is-form="true"
              />

              <MessageBoxWarning
                v-if="!isBrowserOutdated"
                data-testid="error-message-required"
                :message="$t('feature.support_bar.support_form.browser_outdated')"
                :is-form="true"
              />
            </div>

            <PfButtonBar>
              <PfButtonBarButton
                data-testid="support-request-save"
                :is-loading="state === validStates.SUBMIT_PENDING"
                :class="{
                  'is_disabled': !isTextEmpty,
                }"
                @click="onClickSave"
              >
                {{ $t('feature.item_list.send') }}
              </PfButtonBarButton>

              <PfButtonBarLink
                data-testid="support-edit-cancel"
                :is-enabled="state !== validStates.SUBMIT_PENDING"
                @click="onClickCloseSupportBar"
              >
                {{ $t('layout.cancel') }}
              </PfButtonBarLink>

              <template #error>
                <MessageBoxError
                  v-if="state === validStates.SUBMIT_ERROR"
                  data-testid="signature-edit-messageboxerror"
                  :message="$t('feature.components.signature_edit.server_error')"
                />
              </template>

              <template #alt-action>
                <MessageBoxWarning
                  v-if="state === validStates.SUBMIT_WARNING && !form.text"
                  data-testid="support-message-no-input-text"
                  :message="$t('feature.support_bar.support_message')"
                />
              </template>
            </PfButtonBar>
          </div>

          <div
            v-if="state === validStates.SUBMIT_SUCCESS"
            data-testid="submit-message-success"
          >
            <div>
              <MessageBoxSuccess
                :message="$t('feature.support_bar.support_form.submit_success')"
              />

              <br>

              <button
                data-testid="support-form-button-reset"
                class="obj_button uti_margin-top-md"
                @click="onClickReset()"
              >
                <span class="label">
                  {{ $t('feature.support_bar.send_another_support_mail') }}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </PfModal>
</template>

<script>
import { sendSupportMail } from '@client-shared/api/users.api.js'
import vuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import packageJson from '../../package.json'

import MessageBoxError from '@/components/MessageBoxError.vue'
import MessageBoxSuccess from '@/components/MessageBoxSuccess.vue'
import MessageBoxWarning from '@/components/MessageBoxWarning.vue'
import PfButtonBar from '@/components/PfButtonBar.vue'
import PfButtonBarButton from '@/components/PfButtonBarButton.vue'
import PfButtonBarLink from '@/components/PfButtonBarLink.vue'
import PfModal from '@/components/PfModal.vue'
import RichTextEditor from '@/components/RichTextEditor.vue'

export default {
  name: 'HeaderBarSupportBar',

  components: {
    PfButtonBarLink,
    RichTextEditor,
    PfModal,
    PfButtonBarButton,
    PfButtonBar,
    MessageBoxWarning,
    MessageBoxError,
    MessageBoxSuccess,
  },

  emits: [
    'after:close-animation',
    'on-close-support-bar',
  ],

  setup () {
    return {
      v$: vuelidate(),
    }
  },

  data () {
    const validStates = Object.freeze({
      INITIAL: 1,
      SUBMIT_PENDING: 2,
      SUBMIT_ERROR: 3,
      SUBMIT_SUCCESS: 4,
      SUBMIT_WARNING: 5,
    })

    return {
      state: validStates.INITIAL,
      validStates,
      form: {
        text: this.$store.getters['help/supportMessage'],
      },

      selectedFormTopic: {
        label: this.$t('feature.support_bar.support_form.topics.defect'),
        key: 'TOPIC_DEFECT',
      },
    }
  },

  validations: {
    form: {
      text: {
        required,
      },
    },
  },

  computed: {
    setSupportMessage () {
      return this.$store.getters['help/supportMessage']
    },

    isTextEmpty () {
      return this.form.text
    },

    supportLinks () {
      const links = [
        {
          iconName: 'personalSupport',
          href: this.$t('data.pf_links.personal_support'),
          label: this.$t('feature.support_bar.support_box.links.support'),
          key: 'support',
          gaCategory: 'gotoPersonalSupport',
          gaAction: 'gotoPersonalSupport via supportbar',
        },
        {
          iconName: 'tips',
          href: this.$t('data.pf_links.manual_tips'),
          label: this.$t('feature.support_bar.support_box.links.manuals'),
          key: 'tipps',
          gaCategory: 'gotoManualsAndTips',
          gaAction: 'gotoManualsAndTips via supportbar',
        },
        {
          iconName: 'faq',
          href: this.$t('data.pf_links.faq'),
          label: this.$t('feature.support_bar.support_box.links.faq'),
          key: 'faqs',
          gaCategory: 'gotoFAQ',
          gaAction: 'gotoFAQ via supportbar',
        },
        {
          iconName: 'newFeature',
          href: this.$t('data.pf_links.blog_features'),
          label: this.$t('feature.support_bar.support_box.links.features'),
          key: 'features',
          gaCategory: 'gotoFeatures',
          gaAction: 'gotoFeatures via supportbar',
        },

      ]

      if (!this.$config.isDemoserver) {
        links.push({
          iconName: 'screenSupport',
          href: this.$t('data.pf_links.in_app_support'),
          label: this.$t('feature.support_bar.support_box.links.in_app_support'),
          key: 'screenSupport',
          gaCategory: 'gotoScreenSupport',
          gaAction: 'gotoScreenSupport via supportbar',
        })
      }

      return links
    },
  },

  methods: {

    onClickReset () {
      this.state = this.validStates.INITIAL
      this.form.text = ''
      this.v$.form.$reset()
    },

    onHelpRequest (text) {
      this.$store.commit('help/SET_SUPPORT_MESSAGE', text)
      this.form.text = text
    },

    onClickCloseSupportBar () {
      this.$refs.modal && this.$refs.modal.close()
      this.$emit('on-close-support-bar')
    },

    onClickGotoSupportLink (link) {
      this.$ga.event({
        eventCategory: link.gaCategory,
        eventAction: link.gaAction,
        eventLabel: '',
      })
    },

    async onClickSave () {
      if (this.state === this.validStates.SUBMIT_PENDING) {
        return
      }

      if (!this.form.text) {
        this.state = this.validStates.SUBMIT_WARNING
        return
      }

      this.state = this.validStates.SUBMIT_PENDING

      const localStorageKeysToIgnore = [
        this.$config.constants.LOCAL_STORAGE_ACCESS_TOKEN_KEY,
        this.$config.constants.LOCAL_STORAGE_REFRESH_TOKEN_KEY,
      ]
      const localStorage = Object.entries(window.localStorage)

      const localStorageFiltered = localStorage.filter(entry => {
        return !localStorageKeysToIgnore.includes(entry[0])
      })

      const info = {
        url: window.location.href,
        platform: 'Web',
        browser: window.navigator.userAgent,
        buildVersion: packageJson.version,
        language: this.$i18n.locale,
        user: {
          ...this.$auth.user,
          uploadToken: undefined,
        },

        buildDate: packageJson.buildDate,
        localStorage: localStorageFiltered,
      }

      try {
        await sendSupportMail(
          {
            axios: this.$axios,
            info,
            topic: this.selectedFormTopic.key,
            text: this.form.text,
          })
        await this.$auth.fetchUser()

        this.$store.commit('help/SET_SUPPORT_MESSAGE', '')
        this.state = this.validStates.SUBMIT_SUCCESS
      } catch (e) {
        this.state = this.validStates.SUBMIT_ERROR
      }
    },

    isBrowserOutdated () {
      const supportedVersions = {
        Chrome: 90,
        Firefox: 78,
        Safari: 14,
        Edge: 86,
        IE: 11,
      }

      const ua = navigator.userAgent

      let parsedVersion
      let isOutdated = true

      if (/Chrome\/(\d+\.\d+)/.test(ua)) {
        parsedVersion = parseFloat(RegExp.$1)
        isOutdated = parsedVersion < supportedVersions.Chrome
      } else if (/Firefox\/(\d+\.\d+)/.test(ua)) {
        parsedVersion = parseFloat(RegExp.$1)
        isOutdated = parsedVersion < supportedVersions.Firefox
      } else if (/Safari\/(\d+\.\d+)/.test(ua)) {
        parsedVersion = parseFloat(RegExp.$1)
        isOutdated = parsedVersion < supportedVersions.Safari
      } else if (/Edg\/(\d+\.\d+)/.test(ua)) {
        parsedVersion = parseFloat(RegExp.$1)
        isOutdated = parsedVersion < supportedVersions.Edge
      } else if (/MSIE (\d+\.\d+);/.test(ua) || /Trident\/.*rv:(\d+\.\d+)/.test(ua)) {
        parsedVersion = parseFloat(RegExp.$1)
        isOutdated = parsedVersion < supportedVersions.IE
      }

      return isOutdated
    },

  },
}
</script>
